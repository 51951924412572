$green: #4EC9B0
$blue: #9CDDFE
$gray: #D4D4D4
$background: #1E1E1E

$primary-color: #FF1010
$secondary-color: #FFBF00
$third-color: #0000ff
$light-gray: #C0C0C0
$dark-gray: #3D3D3D
$background-gray: #313131
@import 'variables.sass'

.contact
    display: flex
    flex-direction: column
    width: 100%
    justify-content: center
    align-items: center
    @media screen and (max-width:480px)
        width: 100%
    h1
        margin-top: 150px
        font-size: 2rem
        z-index: 2
        margin-bottom: 0
        @media screen and (max-width:1920px)
            margin-top: 150px
        @media screen and (max-width:1366px)
            margin-top: 120px
            font-size: 2rem
        @media screen and (max-width:1280px)
            margin-top: 100px
            font-size: 2rem
        @media screen and (max-width:480px)
            font-size: 1.5rem
            margin-top: 40px

    h2
        margin: 10px 0 20px 0
    
    .success
        z-index: 2
        position: fixed
        margin: 0 0 0 200px
        top: 140px
        background-color: $background
        color: $green
        font-style: italic
        font-size: 3rem
        width: 80%
        padding: 20px 0 5px 45px
        @media screen and (max-width:1920px)
            font-size: 3rem
            top: 140px
        @media screen and (max-width:1480px)
            font-size: 3rem
            top: 90px 
            margin: 0 0 0 225px
        @media screen and (max-width:1366px)
            margin-top: 110px
            font-size: 2rem
        @media screen and (max-width:1280px)
            margin-top: 100px
        @media screen and (max-width:480px)
            width: 90%
            margin-top: 0px
            font-size: 2rem  
            text-align: center  

    h2
        font-size: 1.5rem
        color: white    


    
    .cform
        width: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        @media screen and (max-width:1920px)
            width: 100%
        @media screen and (max-width:480px)
            width: 100%
        h4
            color: $blue
            margin: 10px 0
            font-size: 1.5rem
            @media screen and (max-width:1920px)
                font-size: 1.5rem
            @media screen and (max-width:1366px)
                font-size: 1.5rem
            @media screen and (max-width:480px)
                text-align: center
                font-size: 1.5rem
                margin: 20px 0

    
        input
            @media screen and (max-width:480px)
                max-width: 450px
            padding: 5px 0px
            border: 1px solid $blue
            width: 100%
            color: white
            background-color: black
            font-size: 1.25rem
            margin: 0px
            margin-bottom: 5px
            max-width: 800px

            @media screen and (max-width:1920px)
                max-width: 800px
                font-size: 1.25rem
                padding: 5px
            @media screen and (max-width:1366px)
                max-width: 675px
                font-size: 1.25rem
                padding: 5px

            @media screen and (max-width:480px)
                font-size: 1.25rem
                height: 40px
                padding: 10px 10px
                width: 85%


        
        textarea
            padding: 5px 0px
            color: white
            background-color: black  
            border: 1px solid $blue
            font-size: 1.25rem
            width: 100%
            height: 120px
            margin-bottom: 20px
            max-width: 800px

            @media screen and (max-width:1920px)
                max-width: 800px
                font-size: 1.75rem
            @media screen and (max-width:1366px)
                max-width: 675px
                font-size: 1.25rem
            @media screen and (max-width:480px)
                width: 85%
                padding: 10px 10px

        .submit
            @media screen and (max-width:600px)
                max-width: 570px
            color: white
            background-color: black        
            font-size: 1.25rem
            padding: 10px 0px
            width: 100%
            border: 1px solid $blue
            max-width: 800px

            @media screen and (max-width:1920px)
                max-width: 800px
                font-size: 2rem
            @media screen and (max-width:1366px)
                max-width: 675px
                font-size: 1.5rem
            @media screen and (max-width:480px)
                text-align: center
                font-size: 1.5rem
                margin: 20px 0
                padding: 10px 10px
                width: 90%


        .submit:hover
            background-color: $blue        
            color: black

@import 'variables.sass'

nav
    z-index: 3
    position: fixed
    top: -5px
    display: grid
    margin: 0
    grid-template-columns: repeat(5, 1fr)
    width: 100%
    justify-content: space-between
    align-items: center
    @media screen and (max-width:480px)
        z-index: 2
        position: relative
        top: -5px
        display: flex
        margin: 0
        grid-template-columns: repeat(5, 1fr)
        width: 100%
        justify-content: space-between
        align-items: center
        flex-direction: column


    .links
        display: grid
        grid-template-columns: repeat(50, 1fr)
        grid-column: 2/6
        grid-row: 1
        margin-bottom: 50px
        align-items: center
        text-align: center
        z-index: 0
        z-index: 3

        @media screen and (max-width:480px)
            position: relative
            top: -5px
            display: flex
            margin: 0
            width: 100%
            justify-content: space-between
            align-items: center
            flex-direction: column
            
        .about-nav
            grid-column: 1/10
        .dev-nav
            grid-column: 11/21

        .art-nav
            grid-column: 22/32

        .contact-nav
            grid-column: 33/43
            grid-row: 1

        
        .tab
            
            color: white
            font-size: 1.5rem
            text-decoration: none
            width: 100%
            margin: 0
            @media screen and (max-width: 1366px)
                font-size: 1.5rem
            @media screen and (max-width: 1366px)
                font-size: 1rem
            @media screen and (max-width:480px)
                font-size: 2rem
                margin: 20px 0px




        .tab::before
            content: "<"
            color: $background
        .tab::after
            margin-left: 30px
            content: "/>"
            color: $background
        
        .active
            color: #4EC9B0
            margin: 0
        .active::before
            color: $gray
        .active::after
            color: $gray

    

.logo
    display: flex
    z-index: 1
    justify-items: center
    align-items: center
    grid-column: 1
    grid-row: 1

    @media screen and (max-width:480px)
        margin: 30px 0
        width: 75%

    justify-content: center
    svg
        fill: none
        width: 80%

        #Levi
            fill: $green
        #App
            fill: $green
        #top_outer_bracket
            stroke-width: 3px
            stroke: $blue

        #bottom_outer_bracket
            stroke-width: 3px
            stroke: $blue

        #left_inner_bracket
            stroke-width: 4px
            stroke: $blue

        #right_inner_bracket
            stroke-width: 4px
            stroke: $blue

        #left_L
            fill: $gray

        #right_L
            fill: $gray
        #Slash
            fill: $gray



.background-nav
    grid-column: 1/6
    grid-row: 1
    left: 1000px
    fill: $background
    stroke-width: 2px
    stroke-dasharray: 925 3000
    stroke-dashoffset: -4967
    stroke: $gray

    @media screen and (max-width:480px)
        display: none

.connect
    display: flex
    flex-direction: row
    grid-column: 45/ span 4

    @media screen and (max-width:480px)
        width: 50%
        justify-content: center
        margin: 30px 0

    .linked-in
        width: 100%
        img
            width: 75%
            @media screen and (max-width:480px)
                width: 55%

    .git-hub
        width: 100%
        img
            width: 75%
            @media screen and (max-width:480px)
                width: 55%


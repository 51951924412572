
@import 'variables.sass'

.App
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  max-width: 1920px


@import 'variables.sass'

.Dev
    color: blue
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    @keyframes snake-around
        0%
            box-shadow: -33px 33px 1px -30px $blue, 33px -33px 1px -30px $blue
        50%
            box-shadow: 33px 33px 1px -30px $blue, -33px -33px 1px -30px $blue
        100%
            box-shadow: 33px -33px 1px -30px $blue, -33px 33px 1px -30px $blue
  

    .contents
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        margin: 0
        padding: 0
        align-self: flex-end
        position: sticky
        top: 155px
        right: 70px        
        z-index: 10
        width: 75%
        padding: 15px 0
        background-color: $background
        visibility: hidden
        @media screen and (max-width:1920px)
            top: 155px
            right: 70px
        @media screen and (max-width:1366px)
            top: 105px
            right: 50px
        @media screen and (max-width:1280px)
            top: 95px
        @media screen and (max-width:480px)
            display: none
        .all_sections
            margin: 0
            color: $gray
            font-size: 1.5rem
            @media screen and (max-width:1920px)
                font-size: 1.5rem
            @media screen and (max-width:1366px)
                font-size: 1.25rem
            @media screen and (max-width:1280px)
                font-size: 1rem

        .on_topic
            color: $green
            font-size: 1.5rem
            margin: 0
            @media screen and (max-width:1920px)
                font-size: 1.5rem
            @media screen and (max-width:1366px)
                font-size: 1.25rem
            @media screen and (max-width:1280px)
                font-size: 1rem
        .fifth
            margin-right: 75px
            @media screen and (max-width:1920px)
                margin-right: 0px
        h3
            color: $gray
            margin: 0
            padding: 0

        .arrows
            font-size: 1rem
            background-image: linear-gradient(to right, $green 0%, $green 0%, $gray 0%, $gray 100%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            font-size: 1.5rem
            @media screen and (max-width:1920px)
                font-size: 1.5rem
            @media screen and (max-width:1366px)
                font-size: 1.25rem
            @media screen and (max-width:1280px)
                font-size: 1rem
    section
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 0 2% 20px 2%
        width: 100%

        h1
            z-index: 2
            position: sticky
            margin: 0 0 0 275px
            top: 100px
            background-color: $background
            color: $green
            font-style: italic
            font-size: 3rem
            width: 80%
            padding: 20px 0 5px 45px
            @media screen and (max-width:1920px)
                font-size: 3rem
                top: 100px
            @media screen and (max-width:1480px)
                font-size: 3rem
                top: 90px 
                margin: 0 0 0 225px
            @media screen and (max-width:1366px)
                font-size: 3rem
                top: 85px
            @media screen and (max-width:1280px)
                font-size: 2.5rem
                top: 80px

            @media screen and (max-width:480px)
                top: 0px
                margin: 0
                text-align: center
                padding-top: 10px
                padding-left: 0
                font-size: 2.25rem

                width: 100%
                z-index: 2



        #traction-project
            display: flex
            flex-direction: row
            justify-content: flex-start
            align-items: flex-start
            box-shadow: 0px 62px 0 -60px $gray
            @media screen and (max-width:480px)
                flex-direction: column-reverse
                justify-content: center
                align-items: center
        .project
            display: flex
            flex-direction: row
            width: 100%
            justify-content: flex-start
            align-items: flex-start
            padding: 40px 0 40px 0
            box-shadow: 0px 62px 0 -60px $gray
            @media screen and (max-width:480px)
                flex-direction: column-reverse
                width: 90%
                padding: 20px 0 20px 0


            .left
                display: flex
                flex-direction: column
                width: 100%
                justify-content: center
                align-items: center
                margin-top: 50px
                margin-left: 2%
                margin-right: 2%
                .start-section
                    margin: 0
                @media screen and (max-width:480px)
                    width: 90%
                    margin-top: 20px
               
                .t-logos-drafts
                    display: flex
                    flex-direction: column
                    justify-content: space-between
                    width: 90%
                    margin-bottom: 75px

                    div
                        display: flex
                        justify-content: center
                        flex-wrap: wrap
                        img
                            margin: 10px
                            width: 120px
                            @media screen and (max-width:1920px)
                                width: 120px
                            @media screen and (max-width:1366px)
                                width: 120px
                            @media screen and (max-width:1280px)
                                width: 85px
                            @media screen and (max-width:480px)
                                width: 70px
                        
                    .final
                        display: flex
                        flex-direction: column
                        justify-content: flex-end
                        flex-wrap: wrap
                        align-items: center
                        .final-traction-logo
                            width: 120px
                            @media screen and (max-width:1920px)
                                width: 120px
                            @media screen and (max-width:1366px)
                                width: 120px
                            @media screen and (max-width:1280px)
                                width: 100px
                            @media screen and (max-width:480px)
                                width: 70px
                        .final-traction-logo:hover
                            transform: rotateY(360deg)	
                            transition: transform 1s
                        h6
                            margin: 0
                            color: $green
                            font-size: 1rem
                            @media screen and (max-width:1920px)
                                font-size: 1rem
                            @media screen and (max-width:480px)
                                visibility: hidden
                                content: ''
                        h6::before
                            visibility: visible
                            @media screen and (max-width:480px)
                                position: relative
                                left: 25%
                                content: 'Click Me'
                                width: 70px
                h2
                    color: $blue
                    font-size: 2rem
                    @media screen and (max-width:1920px)
                        font-size: 2rem
                    @media screen and (max-width:1366px)
                        font-size: 1.75rem
                        padding-top: 20px
                    @media screen and (max-width:1280px)
                        font-size: 1.25rem
                        padding-top: 0px
                p
                    color: white
                    width: 100%
                    font-size: 1.25rem
                    line-height: 25px
                    margin-top: 0px
                    @media screen and (max-width:1920px)
                        font-size: 1.25rem
                        line-height: 25px
                        margin-top: 0px

                    @media screen and (max-width:1366px)
                        font-size: 1.25rem 
                        line-height: 25px
                    @media screen and (max-width:1280px)
                        font-size: 1.1rem
                        line-height: 25px
                    @media screen and (max-width:480px)
                        font-size: 1.25rem
                        margin-top: 10px
                .automaton-focus
                    display: flex
                    flex-direction: row
                    width: 100%
                    justify-content: flex-start
                    @media screen and (max-width:480px)
                        flex-direction: column
                    h4
                        color: $green
                        font-size: 1.25rem 
                        margin: 0 0 10px 15px
                        width: 50%
                        @media screen and (max-width:1920px)
                            font-size: 1.25rem 
                            margin: 0 0 10px 15px
                            width: 50%
                        @media screen and (max-width:1366px)
                            font-size: 1.25rem 
                            margin: 0 0 10px 15px
                            width: 45%
                        @media screen and (max-width:1280px)
                            margin: 0 0 10px 0
                            width: 50%
                            font-size: 1.1rem

                    p
                        color: white
                        width: 100%
                        padding: 0
                        margin: 0 0 10px 2%
                        font-size: 1.25rem 
                        line-height: 25px
                        @media screen and (max-width:1920px)
                            font-size: 1.25rem 
                            line-height: 25px
                        @media screen and (max-width:1366px)
                            font-size: 1.25rem 
                            line-height: 25px
                        @media screen and (max-width:1280px)
                            font-size: 1.1rem
                            line-height: 25px
                .focus
                    display: flex
                    flex-direction: row
                    justify-content: space-around
                    width: 100%
                    @media screen and (max-width:1920px)
                        justify-content: space-around
                    @media screen and (max-width:480px)
                        flex-direction: column
                    
                    h4
                        color: $green
                        margin: 10px 0
                        font-size: 1.25rem 
                        margin-left: 15px
                        @media screen and (max-width:1920px)
                            font-size: 1.25rem 
                            margin-left: 15px
                        @media screen and (max-width:1366px)
                            font-size: 1.25rem 
                            margin: 0 0 10px 15px
                        @media screen and (max-width:1280px)
                            margin: 10px 0 
                    .checkG
                        display: flex
                        flex-direction: row
                        width: 20%
                        @media screen and (max-width:1366px)
                            width: 22%
                        @media screen and (max-width:1280px)
                            width: 22%
                        @media screen and (max-width:480px)
                            width: 100%
                        h4
                            color: $green
                            font-size: 1.25rem
                            width: 50%
                            @media screen and (max-width:1920px)
                                font-size: 1.25rem
                                width: 100%
                            @media screen and (max-width:1366px)
                                font-size: 1.25rem
                            @media screen and (max-width:480px)
                                width: 60%
                        .check-group
                            display: flex
                            flex-direction: column
                            justify-content: center
                            align-items: center
                            width: 40%
                            margin: 0
                            @media screen and (max-width:1920px)
                                width: 40%
                                margin: 0
                            @media screen and (max-width:1366px)
                                width: 40%
                                margin: 0
                            @media screen and (max-width:1280px)
                                width: 30%
                                margin: 10px 10px
                            @media screen and (max-width:480px)
                                width: 25%
                            h6
                                margin-top: 5px
                                color: $green
                                margin-bottom: 0
                                width: 100%             
                                text-align: center
                                font-size: 1rem
                                @media screen and (max-width:1920px)
                                    font-size: 1rem
                                @media screen and (max-width:1366px)
                                    font-size: 0.75rem
                                @media screen and (max-width:1280px)
                                    font-size: 0.75rem
                            .checkedGroup
                                width: 50%
                                display: flex
                                justify-content: center            
                
                img
                    width: 70%
                    height: auto

                .tech-used
                    display: flex
                    align-self: center
                    flex-wrap: wrap
                    width: 100%
                    align-items: center
                    justify-content: center
                    margin-top: 15px
                    @media screen and (max-width:1920px)
                        margin-top: 15px
                    @media screen and (max-width:1366px)
                        margin-top: 0px
                    .single-tech
                        display: flex
                        align-items: center
                        flex-direction: column
                        margin: 15px
                        background-color: white
                        padding: 10px
                        box-shadow: -33px 33px 0 -30px $blue, 33px -33px 0 -30px $blue
                        border: 10px solid black

                        img
                            width: 65px
                            @media screen and (max-width:1920px)
                                width: 65px
                            @media screen and (max-width:1366px)
                                width: 50px
                            @media screen and (max-width:1280px)
                                width: 45px
                            @media screen and (max-width:480px)
                                width: 60px
                        .postgres
                            max-width: 100px
                        .javascript
                            max-width: 150px
                        .fullcalendar
                            max-width: 100px
                        .moment
                            max-width: 100px
                        h3
                            margin: 0
                            color: $background
                            font-size: 1rem

                    
            .right
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                width: 100%
                height: 75vh
                z-index: 1
                margin-top: 50px
                @media screen and (max-width:1920px)
                    margin-top: 50px
                @media screen and (max-width:1366px)
                    margin-top: 20px
                @media screen and (max-width:1280px)
                    margin-top: 0px
                @media screen and (max-width:480px)
                    width: 100%
                    margin-top: 0px
                    height: auto
                .calendar
                    width: 50%
                    height: auto
                    @media screen and (max-width:480px)
                        width: 100%
                h2
                    color: $blue
                    font-size: 2.5rem
                    @media screen and (max-width:1920px)
                        font-size: 2.5rem
                    @media screen and (max-width:1366px)
                        font-size: 1.75rem
                    @media screen and (max-width:1280px)
                        font-size: 1.25rem
                p
                    color: white
                    width: 90%
                    font-size: 1.25rem

                img
                    width: 90%
                    height: auto
                    @media screen and (max-width:480px)
                        width: 100%
                        height: auto     

                .check
                    display: flex
                    flex-direction: row
                    width: 100%
                    justify-content: flex-start
                    align-items: flex-start

                    p
                        width: 75%
                        text-align: left
                        margin: 10px 0
                    

                    

                .web-sec
                    display: flex
                    flex-direction: row
                    justify-content: center
                    align-items: center
                    flex-wrap: wrap
                    width: 100%
                    margin-top: 15px
                    @media screen and (max-width:1920px)
                        margin-top: 15px
                    .web-group
                        display: flex
                        flex-direction: column-reverse
                        margin: 0px 20px   
                        .web-link
                            margin: 20px 0px
                            font-size: 1.25rem
                            margin-top: 30px                            
                            color: $gray
                            align-self: center
                            text-decoration: none
                            line-height: 20px
                            @media screen and (max-width:1920px)
                                font-size: 1.25rem
                                margin-top: 30px
                            @media screen and (max-width:1366px)
                                font-size: 1.25rem
                                margin-top: 20px
                            @media screen and (max-width:1280px)
                                margin: 20px 0px
                                font-size: 1rem
                        .web-arrow
                            color: white
                            align-self: center
                            border: 3px solid $blue
                            padding: 10px
                            margin-top: 20px
                            border: 10px solid black
                            box-shadow: -33px 33px 0 -30px $blue, 33px -33px 0 -30px $blue
                            text-decoration: none
                            width: 65px
                            height: 65px
                            font-size: 2.75rem
                            text-align: center
                            padding: 10px
                            @media screen and (max-width:1920px)
                                width: 65px
                                height: 65px
                                font-size: 2.75rem
                                text-align: center
                                padding: 10px
                            @media screen and (max-width:1366px)
                                width: 70px
                                height: 70px
                                font-size: 2.75rem
                                text-align: center
                                padding: 10px
                            @media screen and (max-width:1280px)
                                width: auto
                                height: auto
                                font-size: 2rem
                                text-align: center
                                padding: 10px
                            @media screen and (max-width:480px)
                                text-align: center
                                width: 50px
                                height: 50px
                                padding: 10px

                    .web-group:hover > .web-link
                        color: $green
                    .web-group:hover > .web-arrow
                        animation: snake-around 1s ease-in-out 0s infinite reverse
                        color: $green
                    .web-group:hover
                        cursor: pointer

                    

                
            

.t-logos
    display: flex
    flex-direction: row
    margin-top: 75px

.all_sections:hover
    cursor: pointer






.logo-sect
    display: flex
    flex-direction: column

.UI
    display: flex
    flex-direction: row
    img
        width: 45%
        margin-bottom: 75px


.mapped
    display: flex
    flex-direction: row


    img
        width: 35%
        margin-bottom: 75px
        margin-left: 5%
        margin-right: 5%




.logo-group
    display: flex


div.tech-used
    display: flex


.video
    position: relative
    overflow: hidden
    padding-top: 56.25%
    display: flex
    justify-content: center
    align-items: center
    width: 100%
    iframe
        position: absolute
        width: 95%
        height: 95%
        top: 0
        left: 0
        @media screen and (max-width:480px)
            width: 100%
            height: 100%

#schatz-tech
    width: 100%

#auto
    justify-content: center

#schatz
    justify-content: center

#shoppies-right
    margin-top: 125px



#scheduled
    justify-content: center
    align-items: center
    align-self: center
    width: 100%
    margin-top: 0px
    @media screen and (max-width:480px)
        margin-top: 20px
    .left
        @media screen and (max-width:1920px)
            width: 100%
    h2
        margin-top: 0px
    img
        width: 30%
        margin-bottom: 20px
        @media screen and (max-width:480px)
            width: 70%

#check-box
    justify-content: center
    @media screen and (max-width:1920px)
        justify-content: center
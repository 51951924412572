

@import 'variables.sass'

html
  background-color: $background
  font-family: Arial, Helvetica, sans-serif
  margin: auto
  box-sizing: border-box
  width: 100%
  overflow-x: hidden
*
  max-width: 1920px
  

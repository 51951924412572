
@import 'variables.sass'

.about
    color: blue
    width: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    .firstSect
        margin-top: 100px
    .contents
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center
        margin: 0
        padding: 0
        align-self: flex-end
        position: sticky
        top: 155px
        right: 70px        
        z-index: 10
        width: 75%
        padding: 15px 0
        background-color: $background
        visibility: hidden
        @media screen and (max-width:1920px)
            top: 155px
            right: 70px
        @media screen and (max-width:1366px)
            top: 105px
            right: 50px
        @media screen and (max-width:1280px)
            top: 95px
        @media screen and (max-width:480px)
            display: none

        .all_sections
            margin: 0
            color: $gray
            font-size: 1.5rem
            @media screen and (max-width:1920px)
                font-size: 1.5rem
            @media screen and (max-width:1366px)
                font-size: 1.25rem
            @media screen and (max-width:1280px)
                font-size: 1rem

        .on_topic
            color: $green
            margin: 0
            @media screen and (max-width:1920px)
                font-size: 1.5rem
            @media screen and (max-width:1366px)
                font-size: 1.25rem
            @media screen and (max-width:1280px)
                font-size: 1rem
    
        .fifth
            margin-right: 75px
            @media screen and (max-width:1920px)
                margin-right: 0px
        h3
            color: $gray
            margin: 0
            padding: 0
        .arrows
            background-image: linear-gradient(to right, $green 0%, $green 0%, $gray 0%, $gray 100%)
            -webkit-background-clip: text
            -webkit-text-fill-color: transparent
            font-size: 1.5rem
            @media screen and (max-width:1920px)
                font-size: 1.5rem
            @media screen and (max-width:1366px)
                font-size: 1.25rem
            @media screen and (max-width:1280px)
                font-size: 1rem
    h1
        z-index: 2
        position: sticky
        margin: 0 0 0 275px
        top: 100px
        background-color: $background
        color: $green
        font-style: italic
        font-size: 3rem
        width: 80%
        padding: 20px 0 5px 45px
        @media screen and (max-width:1920px)
            font-size: 3rem
            top: 100px
        @media screen and (max-width:1480px)
            font-size: 3rem
            top: 90px 
            margin: 0 0 0 225px
   
        @media screen and (max-width:1366px)
            font-size: 3rem
            top: 85px
            margin: 0 0 0 200px
        @media screen and (max-width:1280px)
            font-size: 2.5rem
            top: 80px

        @media screen and (max-width:480px)
            top: 0px
            margin: 0
            text-align: center
            padding-top: 10px
            padding-left: 0
            font-size: 2.25rem

            width: 100%
            z-index: 3
    section
        display: flex
        flex-direction: column
        justify-content: space-between
        padding: 0 2% 20px 2%
        width: 100%



        #traction-project
            display: flex
            flex-direction: row
            justify-content: flex-start
            align-items: flex-start
            padding: 0px 0 40px 0
            box-shadow: 0px 62px 0 -60px $gray
        .about-sect
            display: flex
            flex-direction: row
            justify-content: center
            align-items: center
            padding: 0px 0 30px 0
            box-shadow: 0px 62px 0 -60px $gray    
            @media screen and (max-width:1920px)
                align-items: center
            @media screen and (max-width:1480px)
                align-items: center
            @media screen and (max-width:1366px)
                align-items: center
            @media screen and (max-width:1280px)
                align-items: center

            @media screen and (max-width:480px)
                top: 0px
                margin: 0
                text-align: center
                padding-top: 10px
                padding-left: 0
                font-size: 2.25rem

                width: 100%
                z-index: 2
                
            @media screen and (max-width:480px)
                flex-direction: column
                justify-content: center
                align-items: center
                padding: 10px 0 10px 0


            .left
                display: flex
                flex-direction: column
                width: 100%
                justify-content: center
                align-items: center
                margin-left: 2%
                margin-right: 2%
                @media screen and (max-width:480px)
                    width: 100%
                    margin-top: 0px
                .t-logos-drafts
                    display: flex
                    flex-direction: column
                    justify-content: space-between
                    width: 100%
                    margin-bottom: 75px

                    div
                        display: flex
                        justify-content: center

                        img
                            margin: 10px
                            width: 8%
                        
                    .final
                        display: flex
                        flex-direction: column
                        justify-content: flex-end
                        align-items: center
                        .final-traction-logo
                            width: 80%
                        .final-traction-logo:hover
                            transform: rotateY(360deg)	
                            transition: transform 1s
                        h6
                            margin: 0
                            color: $green
                h2
                    color: $blue
                    font-size: 2rem
                    @media screen and (max-width:1920px)
                        font-size: 2rem
                    @media screen and (max-width:1366px)
                        font-size: 1.75rem
                        padding-top: 20px
                    @media screen and (max-width:1280px)
                        font-size: 1.5rem
                        padding-top: 0rem
                        line-height: 40px

                    @media screen and (max-width:480px)
                        font-size: 1.25rem


                p
                    color: white
                    width: 100%
                    font-size: 1.25rem
                    line-height: 25px
                    @media screen and (max-width:1920px)
                        font-size: 1.25rem
                        line-height: 25px
                    @media screen and (max-width:1366px)
                        font-size: 1.2rem
                        line-height: 25px
                    @media screen and (max-width:1280px)
                        font-size: 1.1rem
                        line-height: 23px

                    @media screen and (max-width:480px)
                        width: 90%
                        text-align: left
                        font-size: 1.25rem
                        line-height: 30px
                    br 
                        margin-bottom: 10px



                .about-focus
                    display: flex
                    flex-direction: row
                    width: 100%
                    justify-content: flex-start
                    @media screen and (max-width:480px)
                        width: 90%
                        flex-direction: column
                        font-size: 1.75rem
                    h4
                        color: $green
                        margin: 0 0 10px 0
                        width: 50%
                        font-size: 1.25rem
                        @media screen and (max-width:1920px)
                            font-size: 1.25rem
                        @media screen and (max-width:1366px)
                            font-size: 1.25rem
                        @media screen and (max-width:480px)
                                width: 100%
                                flex-direction: column
                                font-size: 1.25rem
                                margin-bottom: 20px

                    p
                        color: white
                        width: 100%
                        padding: 0
                        margin: 0 0 10px 2%
                        font-size: 1.25rem
                        @media screen and (max-width:1920px)
                            font-size: 1.25rem
                        @media screen and (max-width:1366px)
                            font-size: 1.25rem
                        @media screen and (max-width:1280px)
                            font-size: 1.1rem
                        @media screen and (max-width:480px)
                            width: 100%
                            margin-bottom: 20px
                            line-height: 30px

                .focus
                    display: flex
                    flex-direction: row
                    justify-content: space-between
                    width: 100%

                    h4
                        color: $green
                        font-size: 1.25rem
                        margin: 0 0 20px 0


                
                img
                    width: 70%
                    height: auto        
            .right
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                width: 70%
                @media screen and (max-width:480px)
                    width: 100%
                    margin-top: 0px
                    height: auto

                .tech-used
                    display: flex
                    align-self: center
                    flex-wrap: wrap
                    width: 100%
                    align-items: center
                    justify-content: center
                    margin: 0
                    margin-top: 40px             
                    @media screen and (max-width:1920px)
                        margin-top: 40px             
                    @media screen and (max-width:480px)
                        margin-bottom: 10px
                    .single-tech
                        display: flex
                        align-items: center
                        justify-content: center
                        flex-direction: column
                        margin: 15px
                        background-color: white
                        padding: 10px
                        box-shadow: -33px 33px 0 -30px $blue, 33px -33px 0 -30px $blue
                        border: 10px solid black
                        height: 100px
                        @media screen and (max-width:1366px)
                            height: 100px
                        @media screen and (max-width:1280px)
                            height: 60px                 
                        img
                            width: 100px
                            @media screen and (max-width:1366px)
                                width: 100px
                            @media screen and (max-width:1280px)
                                width: 60px

                        .postgres
                            max-width: 100px
                        .javascript
                            max-width: 150px
                        .fullcalendar
                            max-width: 100px
                        .moment
                            max-width: 100px
                        h3
                            margin: 0
                            color: $background
                            font-size: 1rem
                .calendar
                    width: 50%
                    height: auto
                h2
                    color: $blue
                    font-size: 1.25rem

                p
                    color: white
                    width: 90%
                    font-size: 1.25rem
                    @media screen and (max-width:480px)
                        width: 90%

                img
                    width: 95%
                    height: auto
                    @media screen and (max-width:480px)
                        width: 90%
                
                .self
                    width: 80%
                    @media screen and (max-width:1920px)
                        width: 80%  
                    @media screen and (max-width:1366px)
                        width: 90%
                    @media screen and (max-width:480px)
                        margin-bottom: 20px      
                .check
                    display: flex
                    flex-direction: row
                    width: 100%
                    justify-content: flex-start
                    align-items: flex-start

                    p
                        width: 75%
                        text-align: left
                        margin: 10px 0
                    
                .check-group
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    width: 10%
                    margin: 10px 0
                    h6
                        margin-top: 5px
                        color: $green
                        margin-bottom: 0

                    .checkedGroup
                        width: 50%
                    

                .web-sec
                    display: flex
                    flex-direction: row
                    justify-content: center
                    align-items: flex-start
                    width: 35%
                    @media screen and (max-width:1920px)
                        width: 35%
                    @media screen and (max-width:480px)
                        width: 90%
                    .web-group
                        display: flex
                        flex-direction: column-reverse
                        margin: 0px 20px
                              
                        .web-link
                            margin: 20px 0px
                            color: $gray
                            align-self: center
                            text-decoration: none
                            font-size: 1.25rem
                            @media screen and (max-width:1920px)
                                font-size: 1.25rem
                            @media screen and (max-width:1366px)
                                font-size: 1.25rem
                            @media screen and (max-width:1280px)
                                font-size: 1rem
                            @media screen and (max-width:480px)
                                font-size: 1.25rem
                        
                        width: 90%
                        .web-arrow
                            color: white
                            align-self: center
                            border: 3px solid $blue
                            margin-top: 20px
                            border: 10px solid black
                            box-shadow: -33px 33px 0 -30px $blue, 33px -33px 0 -30px $blue
                            text-decoration: none
                            width: 100px
                            height: 100px
                            font-size: 4rem
                            text-align: center
                            padding: 10px
                            margin-bottom: 5px
                            @media screen and (max-width:1920px)
                                width: 100px
                                height: 100px
                                font-size: 4rem
                                text-align: center
                                padding: 10px
                            @media screen and (max-width:1366px)
                                width: 85px
                                height: 85px
                                font-size: 3rem
                                text-align: center
                                padding: 10px
                            @media screen and (max-width:1280px)
                                width: auto
                                height: auto
                                font-size: 2rem
                                text-align: center
                                padding: 10px
                            @media screen and (max-width:480px)
                                text-align: center
                                width: 50px
                                height: 50px
                                padding: 10px
                    .web-group:hover > .web-link
                        color: $green
                    .web-group:hover > .web-arrow
                        animation: snake-around 1s ease-in-out 0s infinite reverse
                        color: $green
                    .web-group:hover
                        cursor: pointer

                    

                
            

.t-logos
    display: flex
    flex-direction: row
    margin-top: 75px

.all_sections:hover
    cursor: pointer






.logo-sect
    display: flex
    flex-direction: column

.UI
    display: flex
    flex-direction: row
    img
        width: 45%
        margin-bottom: 75px


.mapped
    display: flex
    flex-direction: row
    

    img
        width: 35%
        margin-bottom: 75px
        margin-left: 5%
        margin-right: 5%




.logo-group
    display: flex


div.tech-used
    display: flex


.video
    display: flex
    justify-content: center


#schatz-tech
    width: 60%

#auto
    justify-content: flex-end

#schatz
    justify-content: flex-end

#scheduled
    justify-content: center
    align-items: center
    align-self: center
    width: 100%
    margin-top: 0px
    h2
        margin-top: 0px
    img
        width: 30%
        margin-bottom: 20px
    


.start-section
    margin-top: 0px
    @media screen and (max-width:1280px)
        font-size: 1rem